.primary-navigation {
	font-weight: 700;
	list-style: none;
	margin: 0;
	padding: 0;
}

.primary-navigation__item {
	color: var(--color-foreground);

	/* Offset padding-left from the `a` element so it aligns correctly */
	margin-left: -0.4em;
}

@media (--mq-from-small) {
	.site-header.is-light .primary-navigation__item {
		color: var(--color-background);
	}
}

.primary-navigation__item a {
	color: currentcolor;
	display: block;
	letter-spacing: 0.75px;
	line-height: 1;
	padding: 0.4em;
	text-decoration: none;
	height: 1em;
}

.primary-navigation__item span {
	border-bottom: 2px solid transparent;
	transition: border-color 64ms linear;
}

.primary-navigation__item:hover span,
.primary-navigation__item.current-menu-item span {
	border-bottom-color: currentcolor;
}
