.filter {
	font-size: 0;
	list-style: none;
	margin: 0;
	padding: 0;
}

.filter__item {
	display: inline-block;
	font-size: 1rem;
	margin-top: -0.5em; /* Compensate for padding-top */
	text-transform: capitalize;
}

@media (--mq-up-to-xsmall) {
	.filter__item {
		font-size: 0.875rem;
	}
}

.filter__item a {
	color: currentcolor;
	cursor: pointer;
	display: block;
	padding: 0.5em 1em;
	text-decoration: none;
}

@media (--mq-up-to-xsmall) {
	.filter__item a {
		padding: 0.4em;
	}
}

.filter__item span {
	border-bottom: 2px solid transparent;
	transition: border-color 64ms linear;
}

.filter__item a:hover span,
.filter__item.is-active span {
	border-bottom-color: currentcolor;
}
