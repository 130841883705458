.site-header {
	color: var(--color-foreground);
	position: relative;
	z-index: 1000;
}

.site-header.is-light {
	color: var(--color-background);
}

.site-header__logo {
	color: currentcolor;
	position: fixed;
	top: 24px;
	left: 24px;
	width: 48px;
}

.site-header__logo svg {
	display: block;
}

.site-header__logo path {
	fill: currentcolor;
}

@media (min-width: 768px) {
	.site-header__logo {
		top: 48px;
		right: 48px;
		left: auto;
		width: 48px;
	}
}

@media (min-width: 1024px) {
	.site-header__logo {
		top: 64px;
		right: 64px;
		width: 64px;
	}
}

input[type="checkbox"][name="site-header__mobile-navigation-toggle"] {
	display: none;
}

.site-header__mobile-navigation-toggle {
	box-sizing: border-box;
	display: block;
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: Arial;
	font-size: 32px;
	position: fixed;
	top: 24px;
	right: 10px;
	width: 48px;
	height: 46px;
}

.site-header__mobile-navigation-toggle path {
	fill: var(--color-foreground);
}

.site-header.is-light .site-header__mobile-navigation-toggle path {
	fill: var(--color-background);
}

@media (min-width: 768px) {
	.site-header__mobile-navigation-toggle {
		display: none;
	}
}

.site-header__mobile-navigation-toggle--close {
	top: 12px;
	right: 10px;
}

.site-header__mobile-navigation-toggle--close path,
.site-header.is-light .site-header__mobile-navigation-toggle--close path {
	fill: var(--color-black);
}

.site-header__navigation {
	font-size: 1.25em;
	position: fixed;
}

@media (max-width: 767px) {
	.site-header__navigation {
		background-color: var(--color-white);
		color: var(--color-black);
		opacity: 0;
		padding: 16px 24px;
		top: 0;
		right: 0;
		left: 0;
		transform: translate3d(0, -100%, 0);
		transition:
			transform 512ms ease-in 256ms,
			opacity 256ms linear 256ms;
		z-index: 2000;
	}

	.site-header__navigation > * {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
		transition:
			transform 320ms ease-in 128ms,
			opacity 320ms linear 128ms;
	}

	input[type="checkbox"][name="site-header__mobile-navigation-toggle"]:checked ~ .site-header__navigation {
		box-shadow: 0 2px 4px rgb(0 0 0 / 0.05);
		opacity: 1;
		transform: none;
		transition:
			transform 512ms ease-out,
			opacity 128ms linear;
	}

	input[type="checkbox"][name="site-header__mobile-navigation-toggle"]:checked ~ .site-header__navigation > * {
		opacity: 1;
		transform: none;
		transition:
			transform 320ms ease-out 96ms,
			opacity 128ms linear 96ms;
	}
}

@media (min-width: 768px) {
	.site-header__navigation {
		top: 48px;
		left: 48px;
	}
}

@media (min-width: 1024px) {
	.site-header__navigation {
		top: 64px;
		left: 64px;
	}
}
