@media (--mq-from-small) {
	.grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
}

.grid__item {
	box-sizing: border-box;
	margin-bottom: 24px;
}

@media (--mq-from-small) {
	.grid__item {
		margin-bottom: 120px;
		width: calc(50% - 24px);
	}

	.grid__item:nth-child(3n + 3) {
		padding-right: calc(50% - 220px);
		padding-left: calc(50% - 220px);
		width: 100%;
	}
}

@media (--mq-from-medium) {
	.grid__item {
		width: calc(50% - 48px);
	}
}

@media (--mq-from-large) {
	.grid__item {
		width: calc(50% - 64px);
	}
}
