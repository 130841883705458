.scrollbar {
	border-radius: 0.25rem;
	display: block;
	overflow: hidden;
	position: relative;
	height: 0.5rem;
}

.scrollbar__track {
	background-color: rgb(0 0 0 / 0.05);
	border-radius: 0.25rem;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
}

.scrollbar__thumb {
	background-color: var(--color-grey);
	border-radius: 0.25rem;
	cursor: grab;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	min-width: 4rem;
	z-index: 200;
}

.is-dragging .scrollbar__thumb {
	cursor: grabbing;
}
