.pullout {
	background-color: var(--color-white);
	box-shadow: 4px 0 4px rgb(0 0 0 / 0.05);
	overflow-y: auto;
	padding: 36px 48px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 384px;
	z-index: 2000;
	-webkit-overflow-scrolling: touch;
	transition: transform 512ms ease-in-out;
}

.pullout[hidden] {
	display: block;
	-ms-transform: translateX(-480px); /* IE9 specific because it looks very broken */
	transform: translate3d(-480px, 0, 0);
}

@media (--mq-up-to-small) {
	.pullout {
		display: none;
	}
}

@media (--mq-from-small) {
	.site-wrapper {
		transition: opacity 512ms linear;
	}
}

@media (--mq-from-small) {
	.pullout:not([hidden]) ~ .site-wrapper {
		opacity: 0.15;
	}
}
