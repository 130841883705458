.blog-detail {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding: 24px;
	text-align: center;
	max-width: 1100px;
}

@media (--mq-up-to-xsmall) {
	.blog-detail {
		padding-top: 96px;
	}
}

@media (--mq-from-small) {
	.blog-detail {
		padding: 48px;
	}
}

@media (--mq-from-medium) {
	.blog-detail {
		width: 82.5%;
	}
}

.blog-detail__title {
	font-size: 3.75rem;
	font-weight: 900;
	line-height: 1;
	margin-top: 0;
	margin-bottom: 0.5333em;
}

@media (--mq-up-to-xsmall) {
	.blog-detail__title {
		font-size: 2rem;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	.blog-detail__title {
		margin-right: auto;
		margin-left: auto;
		width: calc(100vw - 200px);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.blog-detail__title {
		margin-right: auto;
		margin-left: auto;
		width: 82.5%;
		max-width: calc(100vw - 320px);
	}
}

.blog-detail__metadata {
	margin-bottom: 36px;
}

@media (--mq-from-small) {
	.blog-detail__metadata {
		margin-bottom: 48px;
	}
}

.blog-detail__category {
	color: var(--color-grey);
	text-transform: capitalize;
}

.blog-detail__category::before {
	content: "·";
	display: inline-block;
	margin-right: 0.25em;
	margin-left: 0.15em;
}

.blog-detail__back {
	color: var(--color-grey);
	display: inline-block;
	margin-top: 36px;
	text-decoration: none;
}

.blog-detail__back:hover {
	color: var(--color-black);
}

.blog-detail__images {
	margin-right: auto;
	margin-bottom: 48px;
	margin-left: auto;
	max-width: 768px;
}

.blog-detail__images img {
	display: block;
	margin-right: auto;
	margin-left: auto;
	max-width: 100%;
}

@media (--mq-from-small) {
	.blog-detail__images img {
		max-height: 50vh;
	}
}

.blog-detail__images img:not(:last-child) {
	margin-bottom: 24px;
}

.blog-detail__content {
	font-weight: 400;
	margin-right: auto;
	margin-left: auto;
	max-width: 568px;
}

.blog-detail__content h1,
.blog-detail__content h2,
.blog-detail__content h3,
.blog-detail__content h4 {
	font-weight: 700;
}

.blog-detail__content ol,
.blog-detail__content ul {
	margin-right: auto;
	margin-left: auto;
	padding-left: 1em;
	text-align: left;
	max-width: 320px;
}

.blog-detail__content a {
	border-bottom: 1px solid currentcolor;
	color: inherit;
	display: inline;
	text-decoration: none;
}

.blog-detail__content :last-child {
	margin-bottom: 0;
}
