:root {
	--font-sans-custom: "neuzeit-grotesk", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
	--font-sans-fallback: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
	--font-serif: "Georgia", serif;
	--color-foreground: #000000;
	--color-background: #f7f7f7;
	--color-white: #ffffff;
	--color-black: #000000;
	--color-grey: #b7b7b7;
}
