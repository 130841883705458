.landing {
	overflow: hidden;
	position: relative;
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	z-index: 100;
}

.landing__logo {
	color: var(--color-background);
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 260px;
	z-index: 2000;
}

@media (--mq-from-xsmall) {
	.landing__logo {
		width: 460px;
	}
}

.landing__logo path {
	fill: currentcolor;
}

.landing__logo svg {
	display: block;
	max-width: 100%;
}

.landing__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
}

.landing__background img {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	-ms-transform: translate(-50%, -50%); /* IE9 specific because it looks very broken */
	transform: translate3d(-50%, -50%, 0);
}
