.collections-overview {
	display: block;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	max-width: 720px;
}

.collections-overview__item {
	display: block;
	position: relative;
}
