.grid-item {
	color: currentcolor;
	display: block;
	text-decoration: none;
}

.grid-item__image {
	display: block;
	margin-right: auto;
	margin-bottom: 12px;
	margin-left: auto;
	max-width: 100%;
	max-height: 512px;
}

.grid-item__meta {
	display: block;
	font-size: 1rem;
}

.grid-item__date {
}

.grid-item__category {
	color: var(--color-grey);
	text-transform: capitalize;
}

.grid-item__category::before {
	content: "·";
	display: inline-block;
	margin-right: 0.25em;
	margin-left: 0.15em;
}
