html {
	font-family: var(--font-sans-fallback);
	font-size: 14px;
	font-weight: 700;
	letter-spacing: -0.0179em;
	line-height: 30px;
	word-spacing: 0;
}

html.wf-active {
	font-family: var(--font-sans-custom);
	font-size: 16px;
	letter-spacing: 0;
	line-height: 1.6667;
	word-spacing: 0;
	-webkit-font-smoothing: antialiased;
}

a {
	outline: 0;
}

h1 {
	font-size: 3.3333em;
	line-height: 1.2;
}

@media (--mq-up-to-small) {
	h1 {
		margin-bottom: 0.4em;
	}
}

h2 {
	font-size: 2.5em;
	line-height: 1.2;
}

@media (--mq-up-to-small) {
	h2 {
		margin-bottom: 0.4em;
	}
}

h2:first-child {
	margin-top: 0;
}

strong {
	font-weight: 700;
}
