@media (--mq-from-small) {
	.scroller {
		position: relative;
		width: 100vw;
		height: 100vh;
	}
}

.scroller__scrollbar {
	display: none;
}

@media (--mq-from-small) {
	.scroller__scrollbar {
		display: block;
		margin-left: -240px;
		position: absolute;
		bottom: 48px;
		left: 50vw;
		width: 480px;
	}
}

@media only all and (min-width: 768px) and (max-height: 740px) {
	.scroller__scrollbar {
		bottom: 14px;
	}
}

.scroller__content {
	box-sizing: border-box;
	padding: 96px 24px 24px;
}

@media (--mq-from-xsmall) {
	.scroller__content {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 568px;
	}
}

@media (--mq-from-small) {
	.scroller__content {
		overflow: hidden;
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 142px 24vw;
		white-space: nowrap;
		width: 100vw;
		height: 100vh;
		-webkit-overflow-scrolling: touch;
		font-size: 0;
	}
}

@media only all and (min-width: 768px) and (max-height: 740px) {
	.scroller__content {
		padding: 36px 24vw;
	}
}

.scroller__block {
	box-sizing: border-box;
	white-space: normal;
}

@media (--mq-up-to-small) {
	.scroller__block {
		display: block;
		margin-bottom: 24px;
	}

	.scroller__block:last-child {
		margin-bottom: 0;
	}
}

@media (--mq-from-small) {
	.scroller__block {
		display: inline-block;
		margin-right: 48px;
		vertical-align: top;
		height: 100%;
		min-height: 100%;
	}
}

.scroller__block--image {
	font-size: 0;
}

@media (--mq-up-to-small) {
	.scroller__block--image {
		max-height: 82.5vh;
	}
}

.scroller__block--image img {
	display: block;
}

@media (--mq-up-to-small) {
	.scroller__block--image img {
		margin-right: auto;
		margin-left: auto;
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}
}

@media (--mq-from-small) {
	.scroller__block--image img {
		outline: 0;
		min-height: 100%;
		max-height: 100%;
	}
}

.scroller__block--text,
.scroller__block--intro,
.scroller__block--credits,
.scroller__block--quote {
	color: var(--color-black);
	font-size: 0.875rem;
	font-weight: 400;
	padding: 32px 16px;
	position: relative;
}

.scroller__block--text a,
.scroller__block--intro a,
.scroller__block--credits a,
.scroller__block--quote a {
	border-bottom: 1px solid currentcolor;
	color: currentcolor;
	cursor: pointer;
	text-decoration: none;
}

@media (--mq-from-xsmall) {
	.scroller__block--text,
	.scroller__block--intro,
	.scroller__block--credits,
	.scroller__block--quote {
		font-size: 1.125rem;
		padding: 48px;
	}
}

@media (--mq-from-small) {
	.scroller__block--text,
	.scroller__block--intro,
	.scroller__block--credits,
	.scroller__block--quote {
		font-size: 1.125rem;
		padding: 80px;
		width: 40vw;
		min-width: 568px;
		max-width: 720px;
	}
}

@media (min-width: 768px) and (max-height: 740px) {
	.scroller__block--text,
	.scroller__block--intro,
	.scroller__block--credits,
	.scroller__block--quote {
		padding: 40px 40px 80px;
	}
}

@media (--mq-from-small) {
	.scroller__block--text .scroller__block__content,
	.scroller__block--intro .scroller__block__content,
	.scroller__block--credits .scroller__block__content,
	.scroller__block--quote .scroller__block__content {
		overflow-y: auto;
		position: relative;
		height: 100%;
	}
}

.scroller__block--text.is-scrollable:not(.has-focus),
.scroller__block--intro.is-scrollable:not(.has-focus) {
	cursor: pointer;
}

.scroller__block--text.is-scrollable::after,
.scroller__block--intro.is-scrollable::after {
	background-image: linear-gradient(180deg, #ffffff00, #ffffff00 25%, #ffffff calc(100% - 6.5rem));
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition:
		opacity 256ms linear,
		z-index 16ms linear;
	z-index: 100;
}

.scroller__block--text.is-scrollable::before,
.scroller__block--intro.is-scrollable::before {
	color: var(--color-grey);
	content: "Click to read";
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 700;
	letter-spacing: 1px;
	position: absolute;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	right: 0;
	bottom: 1.5rem;
	left: 0;
	transition: color 256ms ease-in-out;
	z-index: 200;
}

.scroller__block--text.is-scrollable:hover::before,
.scroller__block--intro.is-scrollable:hover::before {
	color: var(--color-black);
}

.scroller__block--text.is-scrollable.has-focus .scroll__block__content,
.scroller__block--intro.is-scrollable.has-focus .scroll__block__content {
	overflow-y: auto;
}

.scroller__block--text.is-scrollable.has-focus::after,
.scroller__block--intro.is-scrollable.has-focus::after {
	opacity: 0;
	transition:
		opacity 256ms linear,
		z-index 16ms linear 256ms;
	z-index: -1;
}

.scroller__block--text.is-scrollable.has-focus::before,
.scroller__block--intro.is-scrollable.has-focus::before {
	content: "Click to close";
}

.scroller__block--intro.is-scrollable::after {
	background-image: linear-gradient(180deg, #f7f7f700, #f7f7f700 25%, #f7f7f7 calc(100% - 6.5rem));
}

.scroller__block--text {
	background-color: var(--color-white);
}

.scroller__block--credits {
	background-color: transparent;
}

@media (--mq-from-xsmall) {
	.scroller__block--credits {
		font-size: 1rem;
	}
}

.scroller__block--quote {
	background-color: transparent;
	border: 8px solid rgb(0 0 0 / 0.035);
	color: var(--color-grey);
	padding: 24px;
}

.scroller__block--quote h2 {
	font-size: 1.875rem;
	margin-bottom: 1em;
}

.scroller__block--quote small {
	font-size: 1em;
}

.scroller__block--quote p {
	margin-bottom: 0.5em;
}

@media (--mq-from-xsmall) {
	.scroller__block--quote {
		padding: 40px;
	}
}

@media (--mq-from-xsmall) {
	.scroller__block--quote {
		font-size: 1.5rem;
		line-height: 1.35;
		padding: 72px;
		min-width: 680px;
	}

	.scroller__block--quote small {
		font-size: 1.125rem;
	}
}

.scroller__block--video {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.scroller.is-scrolling .scroller__block--video {
	pointer-events: none;
	user-select: none;
}

@media (--mq-up-to-small) {
	.scroller__block--video::before {
		content: "";
		display: block;
		padding-top: 66.6667%;
	}
}

@media (--mq-from-small) {
	.scroller__block--video {
		width: calc((100vh - 2 * 142px) / 9 * 16);
		max-width: calc(100vw - 2 * 48px);
		height: 100%; /* == calc(100vh - 2 * 142px) (see .scroller__content) */
	}
}

.scroller__block--video iframe {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

@media (--mq-up-to-small) {
	.scroller__block--video iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
