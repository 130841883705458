.blog {
	box-sizing: border-box;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: 24px;
	text-align: center;
	max-width: 1100px;
}

@media (--mq-up-to-xsmall) {
	.blog {
		padding-top: 96px;
	}
}

@media (--mq-from-small) {
	.blog {
		padding: 48px;
	}
}

@media (--mq-from-medium) {
	.blog {
		width: 82.5%;
	}
}

.blog__title {
	font-size: 3.75rem;
	font-weight: 900;
	line-height: 1;
	margin-top: 0;
	margin-bottom: 0.5333em;
}

@media (--mq-up-to-xsmall) {
	.blog__title {
		font-size: 2rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.blog__title {
		margin-right: auto;
		margin-left: auto;
		width: 82.5%;
	}
}

.blog__filter {
	margin-bottom: 36px;
}

@media (--mq-from-small) {
	.blog__filter {
		margin-bottom: 48px;
	}
}

.blog__grid {
}

.blog__pagination {
	display: block;
	text-align: center;
}

.blog__pagination-link {
	color: currentcolor;
	cursor: pointer;
	display: inline-block;
	padding: 0.5em 1em;
	text-decoration: none;
	width: 100px;
}

.blog__pagination-link:first-child:last-child {
	text-align: center;
}

@media (--mq-up-to-xsmall) {
	.blog__pagination-link {
		padding: 0.4em;
	}
}

.blog__pagination-link span {
	border-bottom: 2px solid transparent;
	transition: border-color 64ms linear;
}

.blog__pagination-link:hover span {
	border-bottom-color: currentcolor;
}

.blog__pagination-link--previous {
	text-align: right;
}

.blog__pagination-link--previous:not(:last-child) {
	border-right: 1px solid var(--color-grey);
}

.blog__pagination-link--next {
	text-align: left;
}
