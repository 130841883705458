.site-content {
	padding: 94px 0 24px;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.site-content {
		padding: 48px 0;
	}
}

@media (--mq-from-medium) {
	.site-content {
		padding-bottom: 94px;
	}
}
