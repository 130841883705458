.collections-overview-item {
	background-color: var(--color-black);
	color: var(--color-white);
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
}

.collections-overview-item:hover .collections-overview-item__content {
	opacity: 0;
}

.collections-overview-item:hover .collections-overview-item__title,
.collections-overview-item:hover .collections-overview-item__period {
	transform: translateY(-0.5em) scaleY(0.8);
}

.collections-overview-item__content {
	background-color: rgb(0 0 0 / 0.15);
	display: block;
	padding: 24px; /* Update .collections-overview-item__poster height too */
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: opacity 192ms linear;
	z-index: 20;
}

.collections-overview-item__title {
	display: block;
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0.4em;
	position: relative;
	transition: transform 256ms ease-in-out;
	max-width: 60%;
	z-index: 100;
}

.collections-overview-item__period {
	display: block;
	font-size: 0.875rem;
	letter-spacing: 1px;
	line-height: 1;
	position: relative;
	text-transform: uppercase;
	transition: transform 256ms ease-in-out;
	z-index: 100;
}

.collections-overview-item__poster {
	display: block;
	position: relative;
	width: 100%;
	min-height: 256px; /* Safari quickfix */
	z-index: 10;
}
