.collection a {
	color: currentcolor;
	text-decoration: none;
}

@media (--mq-from-small) {
	.scroller__block--intro:not(.is-scrollable) .collection__intro {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
	}
}

@media (--mq-from-small) {
	.collection__credits {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
	}
}

.collection__title {
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	font-style: 2.5rem;
	line-height: 1;
	margin-bottom: 0.1em;
}

@media (--mq-from-small) {
	.collection__title {
		font-size: 3.75rem;
		margin-bottom: 0.4em;
	}
}

.collection__metadata {
	display: block;
	font-size: 0.875rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.collection__metadata:not(:last-child) {
	margin-bottom: 2.1em;
}
